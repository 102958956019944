
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 480px) {
  #input_block{

    display:flex;
    flex-direction: row;
   }
}

/* .smooth-dnd-container.horizontal  {
  display: block;
 
 }
 .smooth-dnd-container.horizontal1  {
  display: grid !important;
 
 } */
.text-color-green {
  color: green;
}

.nav-item {
  list-style-type: none;
}

.chat-send-icons {
  cursor: pointer;
  width: 35px;
  margin: 2px;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  left: 0;
  top: auto !important;
  height: 39px;
  width: 100%;
  z-index: 10000001;
  position: fixed !important;
  border: none;
  border-bottom: 1px solid #6B90DA;
  margin: 0 !important;
  bottom: 0 !important;
  box-shadow: 0 0 8px 1px #999;
}

.card .card-body {
  padding: 10px !important;
}

.MuiTabs-indicator{
  background-color: green !important; 
}

 @media (min-width:180px) and (max-width: 767px){ 

  .sl{
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    /* margin-right: -15%; */
    margin-left: 60%;
    height: 40px;
    margin-top: 0%;

  }
} 


@media (max-width: 768px) {
  .btn.dropdown-toggle span.text-black {
    font-size: 12px;
  }

  .btn-tog {
    padding: 8px 8px;
    min-width: 90px;
    max-width: 90px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .flx-direc {
    margin-left: 50;
    flex-direction: row;
  }
  .e-event-popup{
    margin-top: 65px !important;
  }
}

@media (max-width: 768px) {
  .text-black.mr-2 {
    display: none;
  }

  .btn.dropdown-toggle.status {
    display: none;
  }

  .form-group.row.sta {
    /* margin-left: 600%; */
    display: none;
  }

  .dropdown-item.pro {
    display: none;
  }
}

@media (max-width: 1115px) {
  
  .status-container {
    display: none;
  }

  .mobile-h {
    display: none;
  }

  .mobile-hide {
    display: none;
  }

  .tnb {
    margin-left: 25px;
  }

  .tnnb {
    margin-left: 42% !important;
  }

  .chat-lf {
    margin-left: -500% !important;
  }

  .profi {
    margin-left: 615%;
    margin-bottom: 20%;
  }

  .dcal {
    margin-left: 30%;
  }

  .dchat {
    margin-left: 0%;
  }

  .dchatt {
    margin-left: 8%;
  }

  .profil {
    margin-left: 450%;
    margin-bottom: 5%;
  }

  .nav-item.nav-profile.dropdown.show.mobile-support-container {
    margin-left: 40%;
    margin-right: -65%;
  }

  .proi {
    margin-left: 220%;
    margin-bottom: 20%;
    width: 2px;
    height: 2px;
  }

  .psta {
    margin-left: 58%;
  }

  .stwidth {
    width: 65%;
    /* padding: 2%; */
    margin-top: 20%;
  }

  .custom-height-button {
    border: 2px solid grey;
    border-radius: 10px;
    height: 40px;
    margin-top: 0%;
    overflow: hidden;
  }

  /* .info-c {
    height: 30%;
  } */

  .gs {
    display: none;
  }

  .sp {
    margin-top: 20%;
  }

}

.fade-in-phrase {
  font-size: 16px;
  background: white;
  padding: 13px;
  border-radius: 15px;
  opacity: 0;
  animation: fadeInAnimation 1.5s ease-in-out forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .hcn{
    flex-direction: column;
  }
  .ln{
    margin-left: 25%;
    width: 35%;
  }
}

@media (max-width: 767px){
  .tsk {
    max-width: 100%;
    margin: auto; 
    left: -110%;
    right: 0;
  }
  .drp{
    /* max-width: 100%;*/
    max-height: 80%; 
    overflow-y: auto; 
    /* -webkit-overflow-scrolling: touch; */
  }
}

@media only screen and (max-width: 760px) {
  .modal-dialog.modal-xl {
    max-width: 300px;
  }
  .modal-dialog.modal-xl .modal-content {
  overflow-x: auto; 
  overflow-y: auto;
  max-height: 100vh; 
  margin-bottom: 10%;
}
  .tnj{
    margin-bottom: 15%;
  }
}

@media (max-width: 760px) {
  .ftr {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  }
  .ftr button {
    width: calc(50% - 10%);
    height: calc(50% - 10%); 
    /* margin: 1px; */
}

 /* .sts{
  width: auto;
  overflow: hidden;
  white-space: 'nowrap';
  text-overflow: ellipsis;
 } */

 .pt{
  margin-bottom: -70px;
 }
 .md{
  height: 700px;
  margin-top: 30px;
 }
 .ct{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #1b171736;
  
 }
 .ms{
  margin-top: 10px;
  margin-bottom: 8px;
 }
}

@media (max-width: 760px) {
  .cht{
   white-space: normal !important;
  }
  .arc{
    margin-bottom: 35%;
  }
  .cpc{
    max-width: 400%;
    overflow-y: scroll;
  }
  .grp{
    max-width: 150%;
  }
  .gr-1{
    min-width:65%;
  }
  .tbl{
    margin-left: -10%;
  }
  .lnp{
    margin-top: 5%;
  }
}

@media (min-width:180px) and (max-width: 767px) {
  .hmb {
    margin-right: -10%;
}
.vrs{
  margin-left: 20%;
}
  .pf{
  margin-left: -50%;
 }
 .st{
  margin-left: -32%;
 }
 .vrsi{
  margin-right: -22.5%;
  margin-left: 20%;
 }
 .mtc{
  min-width: 150px;
  max-width: 150px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }
 .sre{
  margin-right: 40%;
 }
}
.custom-tabs .custom-tab {
  text-transform: none; 
  padding: 4px; 
  border: 1px solid black;
  margin-left: 5%;
}
.custom-tabs .MuiButtonBase-root {
  min-width: 40%;
  min-height: 30%; 
}  

@media (min-width:800px) and (max-width:1300px) {
  .adp{
    margin-right: 25%;
  }
  .nrb{
    margin-right: 35%;
    margin-top: 2%;
  }
  .srh{
    margin-right: 58%;
  }
  .sgt{
    margin-right: 5%;
  }
  .sre{
    margin-right: 3%;
    margin-top: -5%;
  }
  .arc{
    margin-bottom: 10%;
  }
  .e-event-popup{
    margin-top: 6%;
  }
  .tsk {
    max-width: 70%;
    margin: auto; 
    left: -40%;
    right: 0;
  }
}

@media (min-width:1200px) and (max-width:1600px) {
  .pjt{
    margin-left: 10%;
  }
  .nbr{
    margin-right: 35%;
  }
  .nrb{
    margin-right: 5%;
  }
  .arc{
    margin-bottom: 10%;
  }
  .e-event-popup{
    margin-top: 5%;
  }
}